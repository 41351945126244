var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',[_c('o-form',[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('o-text-field',{attrs:{"label":"labels.user.email","hide-details":"","prepend-icon":"mdi-at"},model:{value:(_vm.search.email),callback:function ($$v) {_vm.$set(_vm.search, "email", $$v)},expression:"search.email"}})],1),_c('v-col',{attrs:{"cols":"9"}},[_c('o-select',{attrs:{"items":_vm.roles,"label":"messages.views.admin.configuration.users.search.role","hide-details":"","prepend-icon":"mdi-label","clearable":""},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('o-chip-user-role',{attrs:{"role":props.item}})]}},{key:"selection",fn:function(props){return [_c('o-chip-user-role',{attrs:{"role":props.item}})]}}]),model:{value:(_vm.search.role),callback:function ($$v) {_vm.$set(_vm.search, "role", $$v)},expression:"search.role"}})],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.users ? _vm.users.edges.map(function (edge) { return edge.node; }) : [],"server-items-length":_vm.users ? _vm.users.totalCount : 0,"loading":_vm.loading,"height":352,"page":_vm.options.page,"items-per-page":_vm.options.itemsPerPage,"dense":"","disable-sort":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"secondary"},on:{"click":function () { return _vm.openUserFormModal(); }}},[_c('v-icon',{staticClass:"mr-2",domProps:{"innerHTML":_vm._s('mdi-account-plus')}}),_vm._v(" "+_vm._s(_vm.$t('messages.views.admin.configuration.users.createUser'))+" ")],1)],1)]},proxy:true},{key:"item.roles",fn:function(ref){
var item = ref.item;
var index = ref.index;
return _vm._l((item.roles),function(role,i){return _c('o-chip-user-role',{key:("role-" + index + "-" + i),staticClass:"mx-1",attrs:{"role":role,"small":""}})})}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.createdAt,'L LTS'))+" ")]}},{key:"item.activated",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":item.activated ? 'success' : 'danger'},domProps:{"innerHTML":_vm._s(item.activated ? 'mdi-check' : 'mdi-close')}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('o-action-menu',{attrs:{"actions":[
              {
                icon: 'mdi-pencil',
                label: 'Modifier',
                listeners: {
                  click: function () { return _vm.openUserFormModal(item); },
                },
              } ]}})]}},{key:"footer",fn:function(ref){
            var props = ref.props;
return [_c('v-data-footer',_vm._b({attrs:{"disable-items-per-page":"","items-per-page":_vm.options.itemsPerPage,"options":_vm.options},on:{"update:options":function($event){_vm.options=$event}}},'v-data-footer',props,false))]}}])})],1)],1),(_vm.modal.user.show)?_c('user-form-modal',{attrs:{"user-id":_vm.modal.user.id},on:{"saved":_vm.refreshUsers},model:{value:(_vm.modal.user.show),callback:function ($$v) {_vm.$set(_vm.modal.user, "show", $$v)},expression:"modal.user.show"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }