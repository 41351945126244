<template>
  <v-container>
    <v-row>
      <v-col>
        <o-form>
          <v-row>
            <v-col cols="3">
              <o-text-field
                v-model="search.email"
                label="labels.user.email"
                hide-details
                prepend-icon="mdi-at"
              />
            </v-col>
            <v-col cols="9">
              <o-select
                v-model="search.role"
                :items="roles"
                label="messages.views.admin.configuration.users.search.role"
                hide-details
                prepend-icon="mdi-label"
                clearable
              >
                <template v-slot:item="props">
                  <o-chip-user-role
                    :role="props.item"
                  />
                </template>
                <template v-slot:selection="props">
                  <o-chip-user-role
                    :role="props.item"
                  />
                </template>
              </o-select>
            </v-col>
          </v-row>
        </o-form>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="users ? users.edges.map(edge => edge.node) : []"
          :server-items-length="users ? users.totalCount : 0"
          :loading="loading"
          :height="352"
          :page="options.page"
          :items-per-page="options.itemsPerPage"
          dense
          disable-sort
          hide-default-footer
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-spacer />
              <v-btn
                color="secondary"
                @click="() => openUserFormModal()"
              >
                <v-icon
                  class="mr-2"
                  v-html="'mdi-account-plus'"
                />
                {{ $t('messages.views.admin.configuration.users.createUser') }}
              </v-btn>
            </v-toolbar>
          </template>

          <template v-slot:item.roles="{ item, index }">
            <o-chip-user-role
              v-for="(role, i) in item.roles"
              :key="`role-${index}-${i}`"
              :role="role"
              small
              class="mx-1"
            />
          </template>

          <template v-slot:item.createdAt="{ item }">
            {{ item.createdAt|moment('L LTS') }}
          </template>

          <template v-slot:item.activated="{ item }">
            <v-icon
              :color="item.activated ? 'success' : 'danger'"
              v-html="item.activated ? 'mdi-check' : 'mdi-close'"
            />
          </template>

          <template v-slot:item.actions="{ item }">
            <o-action-menu
              :actions="[
                {
                  icon: 'mdi-pencil',
                  label: 'Modifier',
                  listeners: {
                    click: () => openUserFormModal(item),
                  },
                },
              ]"
            />
          </template>

          <template v-slot:footer="{ props }">
            <v-data-footer
              disable-items-per-page
              :items-per-page="options.itemsPerPage"
              :options.sync="options"
              v-bind="props"
            />
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <user-form-modal
      v-if="modal.user.show"
      v-model="modal.user.show"
      :user-id="modal.user.id"
      @saved="refreshUsers"
    />
  </v-container>
</template>

<script>
  import { getRoles } from '@/utils/user';

  export default {
    components: {
      UserFormModal: () => import('@/views/admin/components/configuration/users/UserFormModal'),
    },

    apollo: {
      users: {
        query: require('@gql/views/admin/configuration/users/searchUsers.gql'),
        client: 'api-gateway',
        variables: function () {
          return {
            offset: (this.options.page - 1) * this.options.itemsPerPage,
            first: this.options.itemsPerPage,
            email: this.search.email,
            role: this.search.role,
          };
        },
        fetchPolicy: 'no-cache',
        result (res, key) {
          this.loading = res.loading;
        },
        update: function (data) {
          return data.searchUsers;
        },
      },
    },

    data: function () {
      return {
        roles: getRoles(),
        loading: true,
        options: {
          page: 1,
          itemsPerPage: 10,
        },
        search: {
          email: null,
          role: null,
        },
        headers: [
          {
            text: this.$t('labels.user.email'),
            value: 'email',
            align: 'center',
          },
          {
            text: this.$t('labels.user.roles'),
            value: 'roles',
            align: 'center',
          },
          {
            text: this.$t('labels.user.createdAt'),
            value: 'createdAt',
            align: 'center',
          },
          {
            text: this.$t('labels.user.activated'),
            value: 'activated',
            align: 'center',
          },
          {
            value: 'actions',
            align: 'center',
          },
        ],
        modal: {
          user: {
            show: false,
          },
        },
      };
    },

    watch: {
      options: {
        handler () {
          this.loading = true;
          this.$apollo.queries.users.refresh();
        },
        deep: true,
      },
      search: {
        handler () {
          this.loading = true;
          this.options.page = 1;
        },
        deep: true,
      },
    },

    methods: {
      openUserFormModal (user) {
        this.modal.user.id = user ? user.id : null;
        this.modal.user.show = true;
      },

      refreshUsers () {
        this.$apollo.queries.users.refresh();
      },
    },
  };
</script>
